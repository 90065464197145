<template>
    <section class="services" id="services">
        <div class="container">
            <div class="service-content">
                <div class="service-icon">
                    <i class="fas fa-code"></i>
                </div>
                <h2 class="h2 service-title">{{ this.txtTitle }}</h2>
                <p class="service-text">
                    {{ this.txtService }}
                    <span>{{ this.txtInfoService }}</span>
                </p>
            </div>
            <ul class="service-list">
                <li v-for="(service,i) in services" :key="i">
                    <div class="service-card">
                        <div class="card-icon">
                            <i :class="['fas', service.faicon]"></i>
                        </div>
                        <h3 class="card-title">{{ service.title }}</h3>
                        <p class="card-text">{{ service.content }}</p>
                        <div class="techIcons" v-if="services[i].isFaIcon">
                            <i v-for="(techIcon, j) in services[i].techIcons" :key="j" :class="[techIcon.type, techIcon.icon]" :title="techIcon.txt"></i>
                        </div>
                        <div class="techIconsImg" v-else>
                            <img v-for="(techIconImg, k) in services[i].techIconsImg" :key="k" :class="techIconImg.clname" :src="require(`@/assets/images/${techIconImg.img}`)" :alt="techIconImg.txt" :title="techIconImg.txt" />
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
export default {
    name: 'PublicServices',
    data(){
        return {
            txtTitle: "Services",
            txtService: "Ce que je vous propose en matière de développement.",
            txtInfoService: "Je ne suis pas graphiste. Vous ne trouverez pas de services sur la phase graphique de votre site ou application.",
            services: [
                {
                    id: 1,
                    faicon: 'fa-display',
                    title: "Frontend",
                    content: "La partie visible qu'un utilisateur voit en visitant votre site. À partir d'une maquette fournie par vos soins, je développe tout ou partie de l'interface de votre site.",
                    isFaIcon: true,
                    techIcons: [
                        {
                            type: 'fab',
                            icon: 'fa-html5',
                            txt: "HTML"
                        },
                        {
                            type: 'fab',
                            icon: 'fa-css3-alt',
                            txt: "CSS"
                        },
                        {
                            type: 'fab',
                            icon: 'fa-js',
                            txt: "Javascript"
                        },
                        {
                            type: 'fab',
                            icon: 'fa-vuejs',
                            txt: "VueJS"
                        }
                    ],
                },
                {
                    id: 2,
                    faicon: 'fa-server',
                    title: "Backend",
                    content: "La partie invisible, comme par exemple la base de données ou des API avec des systèmes tiers. Je développe vos fonctionnalités à partir de votre cahier des charges.",
                    isFaIcon: true,
                    techIcons: [
                        {
                            type: 'fab',
                            icon: 'fa-php',
                            txt: "PHP"
                        },
                        {
                            type: 'fab',
                            icon: 'fa-laravel',
                            txt: "Laravel"
                        },
                        {
                            type: 'fab',
                            icon: 'fa-node',
                            txt: 'NodeJS'
                        },
                        {
                            type: 'fas',
                            icon: 'fa-database',
                            txt: "Base de données"
                        }
                    ]
                },
                {
                    id: 3,
                    faicon: 'fa-mobile',
                    title: "Mobile",
                    content: "Besoin d'une application mobile ? Je peux vous aider à développer votre application mobile sur mesure qui répond à tous vos besoins, en respectant votre cahier des charges.",
                    isFaIcon: false,
                    techIconsImg:[
                        {
                            clname: "icon_dart",
                            img: "icoDart_vert.png",
                            txt: "Dart"
                        },
                        {
                            clname: "icon_flutter",
                            img: "icoFlutter_vert.png",
                            txt: "Flutter"
                        }
                    ]
                }
            ]
        }
    }
}
</script>

<style>
/* Section Services */
.services{
    padding-block: var(--section-padding);
    position: relative;
}
.service-content{
    margin-bottom: 30px;
}
.service-icon{
    width: 60px;
    height: 60px;
    background: var(--color-main);
    display: grid;
    place-items: center;
    color: var(--color-mygreen);
    border: 2px solid var(--color-mygreen);
    font-size: 2rem;
    border-radius: 5px;
    margin-bottom: 20px;
}
.service-title{
    margin-bottom: 10px;
}
.service-text{
    color: var(--color-mygray);
    font-weight: var(--fw500);
    line-height: 1.7;
    margin-bottom: 20px;
}
.service-text span{
    display: block;
    background-color: rgba(255, 23, 68, 0.1);
    border-left: 5px solid #ff1744;
    border-radius: 5px;
    font-weight: 400;
    margin: 10px;
    padding: 5px 10px;
}
.service-list{
    display: grid;
    gap: 20px;
}
.service-card{
    padding: 20px;
    text-align: center;
    box-shadow: 0 3px 12px hsla(233, 77%, 10%, 0.06);
    border-radius: 4px;
    transition: var(--transition);
	border: 2px solid var(--color-mygreen);
}
.service-card:hover{
    background: var(--color-mygreen);
    transform: translateY(-5px);
}
.service-card .card-icon{
    width: 60px;
    height: 60px;
    background: var(--color-main);
	border: 2px solid var(--color-mygreen);
    display: grid;
    place-items: center;
    color: var(--color-mygreen);
    font-size: 28px;
    border-radius: 50%;
    margin-inline: auto;
    margin-bottom: 20px;
    transition: var(--transition);
}
.service-card .card-title{
    margin-bottom: 10px;
	color: var(--color-white);
    transition: var(--transition);
}
.service-card:hover .card-title{
	color: var(--color-main);
}
.service-card .card-text{
    color: var(--color-mygray);
    font-size: var(--fs6);
    font-weight: var(--fw500);
    line-height: 1.7;
    transition: var(--transition);
}
.service-card:hover .card-text{
    color: var(--color-main);
}
.techIcons{
    display: flex;
    justify-content: center;
    margin: 10px;
}
.techIcons i{
    margin: 5px;
    font-size: var(--fs3);
    color: var(--color-mygreen);
}
.service-card:hover .techIcons i{
    color: var(--color-main);
}
 .techIconsImg{
    display: flex;
    justify-content: center;
    margin: 10px;
} 
.techIconsImg img{
    margin: 5px;
    width: 25px;
}
.service-card:hover .techIconsImg .icon_dart{
    content: url("@/assets/images/icoDart_bg.png");
}
.service-card:hover .techIconsImg .icon_flutter{
    content: url("@/assets/images/icoFlutter_bg.png");
}
</style>