<template>
    <div>
        <PublicHeader/>

        <!-- Section Services -->
        <PublicServices/>

        <!-- Section Process -->
        <PublicProcess/>

        <!-- Section Contact -->
        <PublicContact/>
        
        <ScrollTopButton/>
        <PublicFooter/>
    </div>
</template>

<script>
import PublicHeader from '@/components/public/PublicHeader.vue';
import PublicServices from '@/components/public/PublicServices.vue';
import PublicProcess from '@/components/public/PublicProcess.vue';
import PublicContact from '@/components/public/PublicContact.vue';
import PublicFooter from '@/components/public/PublicFooter.vue';
import ScrollTopButton from '@/components/public/ScrollTopButton.vue';

export default{
    name: 'PublicHome',
    components: {
        PublicHeader,
        PublicServices,
        PublicProcess,
        PublicContact,
        PublicFooter,
        ScrollTopButton
    }
}
</script>