<template>
    <div>
        <ul class="social-list">
            <li v-for="link in tabLinks" :key="link.id">
                <a :href="link.linkIcon" target="_blank" class="social-link">
                    <i :class="[link.typeIcon, link.icon]"></i>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "LinksContact",
    data (){
        return {
            tabLinks:[
                {
                    id: 1,
                    typeIcon: "fab",
                    icon: "fa-linkedin",
                    linkIcon: "https://fr.linkedin.com/in/ahmed-krichel-675283106/fr"
                },
                {
                    id: 2,
                    typeIcon: "fas",
                    icon: "fa-envelope",
                    linkIcon: "mailto:akrichel@akdevmatik.fr"
                }
            ]
        }
    }
}
</script>

<style>
.social-list{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}
.social-link{
    color: var(--color-mygray);
    font-size: 20px;
    transition: var(--transition);
}
.social-link:is(:hover, :focus) { color: var(--color-mygreen); }
</style>