<template>
    <div class="blocModale" v-if="showModale">
        <div class="overlay"></div>

        <div class="modale card">
            <!-- <div class="btnModale" @click="toggleModale"><i class="fas fa-xmark"></i></div> -->
            <div v-if="typeContentModale === 'mLegales'">
                <PublicMLegales/>
            </div>
            <div v-else-if="typeContentModale === 'polconf'">
                <PublicPolitique/>
            </div>
            <button class="btnClose" @click="toggleModale">Fermer</button>
        </div>
    </div>
</template>

<script>
import PublicMLegales from '@/components/public/PublicMLegales.vue';
import PublicPolitique from '@/components/public/PublicPolitique.vue';

export default {
    name: 'ModaleVue',
    props: ['showModale', "toggleModale", 'typeContentModale'],
    components: {
        PublicMLegales,
        PublicPolitique
    }
}
</script>

<style>
.blocModale{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.overlay{
    background: rgba(17,23,41,0.7);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.modale{
    background: var(--color-main);
    color: var(--color-mygray);
    border: 2px solid var(--color-mygreen);
    border-radius: 10px;
    padding: 50px;
    position: fixed;
    min-width: 50%;
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
}
.btnModale{
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: var(--color-mygreen);
    border-radius: 15%;
    border: 2px solid var(--color-mygreen);
    padding: 5px;
}
.btnClose {
    position: absolute;
    right: 20px;
    color: var(--color-mygreen);
    padding: 10px 30px;
    border: 2px solid var(--color-mygreen);
    border-radius: 6px;
    font-weight: var(--fw700);
    transition: 0.5s;
    margin-bottom: 25px;
    text-align: center;
}
.btnClose:hover{
    background: var(--color-mygreen);
    border: 2px solid var(--color-mygreen);
    color: var(--color-main);
}
</style>