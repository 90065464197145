<template>
    <div class="contentNavbar">
        <!-- logo -->
        <a href="#" class="logo">
            <img src="@/assets/images/logo.png" alt="AKdevmatik logo">
        </a>

        <button class="menu-toggle-btn" @click="showMenu()">
            <i class="fas fa-bars" v-if="!this.showMobileMenu"></i>
            <i v-else class="fas fa-xmark"></i>
        </button>
        
        <!-- menu -->
        <nav class="navbar" :class="this.showMobileMenu ? 'open-menu' : 'close-menu'">
            <ul class="navbar-list">
                <li><a href="#services" class="navbar-link">Services</a></li>
                <li><a href="#processus" class="navbar-link">Votre projet</a></li>
                <li><a href="#contact" class="navbar-link">Contact</a></li>
            </ul>

            <!-- <div class="header-actions">
                <a href="#" class="header-action-link">Se connecter</a>
                <a href="#" class="header-action-link">S'inscrire</a>
            </div> -->
        </nav>
    </div>
</template>

<script>
export default {
    name: 'PublicNav',
    data (){
        return { showMobileMenu: false};
    },
    methods: {
        showMenu(){
            this.showMobileMenu = !this.showMobileMenu;
            console.log(this.showMobileMenu);
        }
    }
}
</script>

<style>
.contentNavbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header .logo img{
    display: block;
    width: 150px;
}
.menu-toggle-btn{ 
    color: var(--color-white);
    font-size: 1.563rem; 
}
.navbar{
    position: absolute;
    width: 100%;
    top: 4rem;
    left: 0;
    padding-inline: 1.875rem;
    visibility: hidden;
    opacity: 0;
    transition: 0.5s ease-in-out;
}
.header.active .navbar{
    visibility: visible;
    opacity: 1;
}
.navbar-link, .header-action-link{
    color: var(--color-white);
    font-size: 1.1em;
    font-weight: var(--fw700);
    font-family: var(--f-crimsontext);
    padding-block: 0.5rem;
}
:is(.navbar-link, .header-action-link):is(:hover, :focus) {
    color: var(--color-mygreen);
}
</style>