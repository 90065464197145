<template>
    <section class="contact" id="contact">
        <div class="container">
            <div class="contact-content">
                <div class="contact-icon">
                    <i class="fas fa-calendar-days"></i>
                </div>
                <h2 class="h2 contact-title">Contact</h2>
                <p class="contact-text">
                    Vous recherchez un développeur pour un projet ?<br/>
                    Veuillez remplir le formulaire pour réserver un rendez-vous !
                </p>
                <LinksContact/>
            </div>
            <div class="form-calendly">
                <!-- Calendly inline widget begin -->
                <div class="calendly-inline-widget" data-url="https://calendly.com/calendarweb/akdevmatik"></div>
                <!-- Calendly inline widget end -->
            </div>
        </div>
    </section>
</template>

<script>
import LinksContact from "@/components/public/LinksContact.vue";

export default {
    name: "PublicContact",
    components:{
        LinksContact
    }
}
</script>

<style>
/* Contact */
.contact{
    padding-block: var(--section-padding);
    background: var(--color-main) url(@/assets/images/World-Map2.svg) no-repeat fixed;
    background-size: cover;
    background-position: center;
}
.contact .container{
    display: flex;
    justify-content: space-evenly;
}
.contact-content{
    margin-bottom: 30px;
    margin-right: 10px;
}
.contact-icon{
    width: 60px;
    height: 60px;
    background: var(--color-main);
    display: grid;
    place-items: center;
    color: var(--color-mygreen);
    border: 2px solid var(--color-mygreen);
    font-size: 2rem;
    border-radius: 5px;
    margin-bottom: 20px;
}
.contact-title{
    margin-bottom: 10px;
}
.contact-text{
    color: var(--color-mygray);
    font-weight: var(--fw500);
    line-height: 1.7;
    margin-bottom: 20px;
}
.contact .form-calendly{
    width: 100%;
}
.contact .form-calendly .calendly-inline-widget{
    border: 2px solid var(--color-mygray);
    border-radius: 5px;
    /* min-width:320px; */
    height: 616px;
}
.contact .container{
    background-color: rgba(17, 23, 41, 0.7);
    padding: 30px;
    border-radius: 25px;
    border: 2px solid var(--color-mygreen);
}
</style>