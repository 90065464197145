<template>
    <button ref="topButton" class="top-button" @click="scrollToTop()">
        <i class="fas fa-arrow-up"></i>
    </button>
</template>

<script>
    import { onMounted, ref, onBeforeMount } from 'vue';
    export default {
        name: 'ScrollTopButton',
        setup (){
            const topButton = ref();
            const userScroll = () => {
                if(window.scrollY > 200) {
                    topButton.value.classList.add("showButton");
                }else{
                    topButton.value.classList.remove("showButton");
                }
            };
            onMounted(() => {
                window.addEventListener("scroll", userScroll);
            });
            onBeforeMount(() => {
                window.removeEventListener("scroll", userScroll);
            });
            return { topButton };
        },
        methods: {
            scrollToTop(){
                window.scrollTo({ top: 0, behavior: "smooth"});
                location.hash = "";
            }
        }
    };
</script>

<style>
.top-button{
    background-color: var(--color-main);
    color: var(--color-mygreen);
    border-radius: 50%;
    height: 50px;
    width: 50px;
    padding: 4px;
    border: 2px solid var(--color-mygreen);
    cursor: pointer;
    position: fixed;
    right: 40px;
    bottom: 40px;
    display: none;
}
.top-button:hover{
    background-color: var(--color-mygreen);
    color: var(--color-main);
    border: 2px solid var(--color-main);
}
.showButton{
    display: block;
}
</style>