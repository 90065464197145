<template>
    <!-- Header -->
    <header class="header">
        <div class="container">
            <!-- navbar -->
            <PublicNav/>

            <PublicHero/>
        </div>
    </header>
    <!-- End Header -->
</template>

<script>
import PublicNav from '@/components/public/PublicNav.vue';
import PublicHero from '@/components/public/PublicHero.vue';

export default{
    name: 'PublicHeader',
    components: {
        PublicNav,
        PublicHero
    }
}
</script>

<style>
/* Header */
.header{
    /* position: fixed; */
    background:
    radial-gradient(
        ellipse,
        rgba(17, 23, 41, 0.65) 30%,
        rgba(17, 23, 41, 1) 60%
        ),
        url("@/assets/images/bg.png") no-repeat fixed;
    background-size: cover;
    top: 0;
    left: 0;
    width: 100%;
    padding-block: 1.25rem;
    transition: 0.5s ease-in-out;
    z-index: 4;
}
.header.active{
    height: 20.625rem;
}
</style>