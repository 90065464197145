<template>
    <div class="content-public">
        <router-view/>
    </div>
</template>

<script>
export default{
    name: 'PublicLayout'
}
</script>