<template>
  <router-view/>
</template>

<script>
</script>

<style>
  @import '@/assets/css/styles.css';
</style>
