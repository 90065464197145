<template>
    <!-- Hero -->
    <div class="hero" id="hero">
        <div class="container">
            <div class="content-hero">
                <h1 class="h1 title-hero">{{ this.titleHero }}</h1>
                <p class="text-hero">{{ this.textHero }}</p>
                <a href="#contact" class="btn">{{ this.btnHero }}</a>
            </div>
        </div>
    </div>
    <!-- End Hero -->
</template>

<script>
export default {
    name: 'PublicHero',
    data (){
        return {
            titleHero: "Développeur informatique indépendant",
            textHero: "AKdevmatik est le nom commercial d'une entreprise individuelle (EI), créée en mai 2022, proposant des services de développement informatique. Votre partenaire unique pour les petits et grands projets.",
            btnHero: "Contact"
        }
    }
}
</script>

<style>
/* Section Hero */
.hero .container{
    height: 100vh;
}
.content-hero{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(17, 23, 41, 0.7);
    border-radius: 25px;
    padding: 30px;
    border: 2px solid var(--color-mygreen);
}
.title-hero{
    margin-bottom: 25px;
    text-transform: capitalize;
    font-size: 3.5rem;
}
.text-hero{
    color: var(--color-mygray);
    font-size: var(--fs4);
    font-weight: var(--fw500);
    line-height: 1.8;
    margin-bottom: 40px;
}
.content-hero a{
    color: var(--color-mygreen);
    text-transform: uppercase;
    padding: 10px 30px;
    border: 2px solid var(--color-mygreen);
    border-radius: 6px;
    font-weight: var(--fw700);
    transition: 0.5s;
}
.content-hero a:hover{
    background: var(--color-mygreen);
    border: 2px solid var(--color-mygreen);
    color: var(--color-main);
}
</style>