<template>
    <section class="processus" id="processus">
        <div class="container">
            <h2 class="h2 section-title">{{ this.txtTitle }}</h2>
            <p class="section-text">{{ this.txtProcess }}</p>
            <ol class="process-list">
                <li class="process-item" v-for="process in processus" :key="process.id">
                    <i :class="['process-icon', 'fas', process.faicon]"></i>
                    <span class="number">{{ process.id }}</span>
                    <h3 class="h3 process-item-title">{{ process.title }}</h3>
                    <p class="process-item-text">{{ process.content }}</p>
                    <center><button v-if="process.displayBtn" @click="scrollTo(process.scrollTo)">
                        {{ process.titleBtn }}
                    </button></center>
                </li>
            </ol>
        </div>
    </section>
</template>

<script>
export default {
    name: "PublicProcess",
    data (){
        return {
            txtTitle: "Pour une collaboration réussie !",
            txtProcess: "Un processus de travail pour votre projet en 5 étapes claires.",
            processus: [
                {
                    id: 1,
                    faicon: "fa-clipboard-list",
                    // title: "Besoin ?",
                    // content: "Votre expression de besoin, maquette ou cahier des charges. Planifiez votre rendez-vous pour cette première étape.",
                    title: "Définition du besoin",
                    content: "Transmettez les documents nécessaires à la compréhension du projet en planifiant notre rendez-vous. Validons ensemble un cahier des charges et/ou une maquette de votre projet.",
                    displayBtn: true,
                    titleBtn: "Rendez-vous",
                    scrollTo: "contact"
                },
                {
                    id: 2,
                    faicon: "fa-file-pen",
                    title: "Devis & Contrat",
                    content: "Après un échange concernant le besoin de votre projet, je vous propose un devis et un contrat pour officialiser la collaboration."
                },
                {
                    id: 3,
                    faicon: "fa-code",
                    title: "Développement",
                    content: "Les documents signés et l'acompte payé ? Je débute le développement de votre projet en fonction de tous les éléments fournis par vos soins."
                },
                {
                    id: 4,
                    faicon: "fa-clipboard-check",
                    title: "Tests & Validation",
                    content: "Je vous recontacte pour valider la prestation et éventuellement la prise en compte de vos remarques si besoin."
                },
                {
                    id: 5,
                    faicon: "fa-box",
                    //title: "Livraison",
                    title: "Solde & Livraison",
                    //content: "Après validation de votre part, je vous livre tout le code du projet en échange du reste à payer. Votre projet est terminé et j'espère le début pour d'autres collaborations."
                    content: "Après validation de votre part, la facture de solde vous est adressée. Dès réception de son règlement, l'ensemble des développements est livré. Votre projet est terminé et j'espère le début pour d'autres collaborations."
                }
            ]
        }
    },
    methods: {
        scrollTo(hash){
            location.hash = "#"+hash;
        }
    }
}
</script>

<style>
/* Processus */
.processus{
    padding-block: var(--section-padding);
    text-align: center;
}
.process-list{
    display: grid;
    gap: 30px;
}
.process-item{
    position: relative;
    width: 100%;
}
.process-item .process-icon{
    border: 2px solid var(--color-mygreen);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-inline: auto;
    margin-block-end: 20px;
    display: grid;
    place-items: center;
    color: var(--color-mygreen);
    font-size: var(--fs2);
}
.process-item .number{
    position: absolute;
    color: var(--color-main);
    top: -31px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--color-mygreen);
    display: grid;
    place-items: center;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    border: 3px solid var(--color-main);
    font-weight: var(--fw700);
}
.process-item .process-item-text{
    padding: 15px;
    color: var(--color-mygray);
    font-size: var(--fs5);
    line-height: 1.5;
    font-weight: var(--fw500);
    text-align: justify;
}
.process-item button{
    color: var(--color-mygreen);
    padding: 10px 30px;
    border: 2px solid var(--color-mygreen);
    border-radius: 6px;
    font-weight: var(--fw700);
    transition: 0.5s;
    margin-bottom: 25px;
    text-align: center;
}
.process-item button:hover{
    background: var(--color-mygreen);
    border: 2px solid var(--color-mygreen);
    color: var(--color-main);
}
</style>