import { createRouter, createWebHistory } from 'vue-router'

import * as Public from '@/views/public'

const routes = [
    {
        path: '/',
        name: 'public',
        component: Public.Layout,
        children: [
            {path: '/', name: 'home', component: Public.Home}
        ]
    },
    {
        path: '/:pathMatch(.*)*', redirect: '/'
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router