<template>
    <div class="container">
        <h1>{{ this.titlePolitique }}</h1>
        <span v-html="this.subTitleP"></span>
        <div class="content">
            <div class="sectionP" v-for="content in contentPolitique" :key="content.id">
                <h2>{{ content.titleSection }}</h2>
                <p v-html="content.contentSection"></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PublicPolitique",
    data (){
        return {
            titlePolitique: "Politique de confidentialité",
            subTitleP: "La présente politique de confidentialité a pour but d'informer les utilisateurs du site wwww.akdevmatik.fr :<br/><ul><li>Sur la manière dont sont collectées leurs données personnelles.</li><li>Sur les droits dont ils disposent concernant ces données</li><li>Sur la politique du site en matière de cookies</li></ul>",
            contentPolitique: [
                {
                    id: 1,
                    titleSection: "Nature des données collectées",
                    contentSection: "Tout internaute utilisant le site suivant : www.akdevmatik.fr est considéré comme utilisateur du site.<br/><br/>Le site www.akdevmatik.fr est susceptible de collecter les catégories de données suivantes concernant ses utilisateurs : <br/>Données d'état-civil, d'identité, d'identification...<br/>Données de connexion (adresses IP, journaux d'évènements...)"
                },
                {
                    id: 2,
                    titleSection: "Communication des données personnelles à des tiers",
                    contentSection: "Vos données ne font l'objet d'aucune communication à des tiers. Vous êtes toutefois informés qu'elles pourront être divulguées en application d'une loi, d'un règlement ou en vertu d'une décision d'une autorité règlementaire ou judiciaire compétent."
                },
                {
                    id: 3,
                    titleSection: "Liens et cookies",
                    contentSection: "Le site www.akdevmatik.fr contient des liens hypertextes vers des sites externes ou d'information.<br/><br/>Le propriétaire du site ne peut pas vérifier l'ensemble du contenu des différents sites ainsi visités et décline donc toute responsabilité de cette façon quand aux eventuels risques de contenus interdits.<br/><br/>L'utilisateur est avisé que lors de ses visites sur le site www.akdevmatik.fr, un ou des cookies peuvent s'installer automatiquement sur son ordinateur.<br/><br/>Un cookie c'est un assemblage de donnée qui ne permettra pas de savoir qui est l'utilisateur, en revanche qui note des renseignements relatives à la navigation de celui-ci sur le site.<br/><br/>Les cookies utilisés sur le site sont : <br/> <ul><li>Calendly : un outil permettant de prendre un rendez-vous. Les données recoltées sont : prénom, nom, e-mail, téléphone et texte libre pour expliquer votre besoin.<br/><br/>Ces informations sont transférées et conservées hors Union européenne dans les conditions de traitement similaire au RGPD.</li></ul><br/>Vous pouvez désactiver vos cookies via cette information explicative du site de la CNIL, en suivant ce <a href='https://www.cnil.fr/fr/cookies-et-autres-traceurs/comment-se-proteger/maitriser-votre-navigateur' target='_blank'>lien</a>"
                },
                {
                    id: 4,
                    titleSection: "Protection des données",
                    contentSection: "En France, les données personnelles sont notamment protégées par la loi n° 78-87 du 6 janvier 1978 et la loi n° 2004-801 datant du 6 août 2004, l'article L. 226-13 du Code pénal ainsi que la Directive Européenne du 24 octobre 1995.<br/><br/>Le site www.akdevmatik.fr collecte des informations personnelles pour le besoin de certains services proposés (prise de rendez-vous, etc...). L'utilisateur offre ces données en toute connaissance de cause, notamment lorsqu'il effectue de lui-même à leur saisie par des formulaires.<br/><br/>Conformément aux dispositifs des articles 38 et autres de la loi 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, tout utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition à toutes les données personnelles qui le concerne.<br/><br/>Pour l'exercer, adressez votre demande à www.akdevmatik.fr par mail <a href='mailto:akrichel@akdevmatik.fr'>akrichel@akdevmatik.fr</a><br/><br/>Aucune information personnelle de l'utilisateur du site www.akdevmatik.fr ne sera publiée sans consentemment de l'utilisateur, échangée, transférée, vendue sur un support quelconque à des tiers."
                },
                {
                    id: 5,
                    titleSection: "Conditions de modification de la politique de confidentialité",
                    contentSection: "L'éditeur du site se réserve le droit de pouvoir modifier la présente politique à tout moment afin d'assurer aux utilisateurs du site de sa conformité avec le droit en vigueur.<br/><br/>L'utilisateur est invité à prendre connaissance de cette politique à chaque fois qu'il visite le site www.akdevmatik.fr, sans qu'il soit nécessaire de l'en prévenir formellement."
                }
            ]
        }
    }
}
</script>

<style>
.container{
    margin: 10px;
}
.container h1{
    text-align: center;
    margin-bottom: 40px;
    color: white;
}
.container span{
    line-height: 1.5;
    margin-bottom: 30px;
}
.container span ul{
    margin: 20px;
}
.container span ul li{
    list-style: square;
}
.container .content{
    padding: 10px;
    margin-bottom: 60px;
}
.sectionP h2{
    color: white;
    margin-bottom: 20px;
}
.sectionP p{
    padding: 0 20px;
    line-height: 1.5;
    margin-bottom: 40px;
}
.sectionP p ul{
    margin: 20px;
}
.sectionP p ul li{
    list-style: square;
}
.sectionP p a{
    color: var(--color-mygreen);
    display: inline;
}
</style>