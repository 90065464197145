<template>
    <footer id="footer">
        <div class="ftop">
            <div class="container">
                <div class="fbrand">
                    <a href="#" class="logo">
                        <img src="@/assets/images/logo.png" alt="AKdevmatik logo">
                    </a>
                    <p class="ftext">
                        {{ this.textFooter }}
                    </p>
                    <LinksContact/>
                </div>
                <div class="flink-box">
                    <ul class="flist">
                        <li><p class="fitem-title">Informations légales</p></li>
                        <li v-for="llegale in linksLegales" :key="llegale.id">
                            <a href="#footer" class="flink" @click="toggleModale(llegale.paramLink)">{{ llegale.textLink }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="fbottom">
            <div class="container">
                <p class="copyright">&copy; 2022 - <a href="#">AKdevmatik</a> - tous droits reservés.</p>
            </div>
        </div>
    </footer>
    <Modale :showModale="showModale" :toggleModale="toggleModale" :typeContentModale="typeContentModale"/>
</template>

<script>
import Modale from "@/components/public/ModaleVue.vue";
import LinksContact from "@/components/public/LinksContact.vue";
import '@/assets/css/mqstyles.css';

export default{
    name: 'PublicFooter',
    data (){
        return {
            textFooter: "Votre partenaire unique pour tous vos besoins en matière de programmation informatique.",
            linksLegales: [
                {
                    id: 1,
                    paramLink: "mLegales",
                    textLink: "Mentions légales"
                },
                {
                    id: 2,
                    paramLink: "polconf",
                    textLink: "Politique de confidentialité"
                }
            ],
            showModale: false,
            typeContentModale: ""
        }
    },
    components: {
        Modale,
        LinksContact
    },
    methods: {
        toggleModale (typeContent){
            this.showModale = !this.showModale;
            this.typeContentModale = typeContent;
        }
    }
}
</script>

<style>

/* Footer */
footer{
    background-color: var(--color-main);
    color: var(--color-white);
    font-weight: var(--fw500);
}
.ftop{
    padding-block: var(--section-padding);
}
.fbrand{
    margin-bottom: 60px;
}
footer .fbrand .logo img{
    display: block;
    width: 150px;
    margin-bottom: 25px;
}
.ftext{
    color: var(--color-mygray);
    font-size: var(--fs6);
    line-height: 1.5;
    margin-bottom: 25px;
}
.flink-box{
    display: flex;
    margin-bottom: 25px;
    width: 300px;
    gap: 50px;
}
.flist li:first-child { margin-bottom: 20px; }
.fitem-title{
    color: var(--color-white);
    font-family: var(--f-mulish);
    font-weight: var(--fw700);
    width: 200px;
}
.flink, .flink2{
    color: var(--color-mygray);
    font-size: var(--fs6);
    transition: var(--transition);
    padding-block: 10px;
}
.flink:is(:hover, :focus){
    color: var(--color-mygreen);
    transform: translateX(5px);
}
.fbottom{
    background: var(--color-main);
    padding-block: 20px;
    text-align: center;
	color: var(--color-mygray);
    margin-top: -50px;
}
.fbottom .container{
    border-top: 1px solid var(--color-mygray);
    padding: 15px;
}
.copyright a{
    display: inline-block;
    color: var(--color-mygray);
    transition: var(--transition);
}
.copyright a:is(:hover, :focus) { color: var(--color-mygreen); }
</style>