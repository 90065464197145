<template>
    <div class="container">
        <h1>{{ this.titleMlegales }}</h1>
        <span class="subSection" v-html="this.subSection"></span>
        <div class="content">
            <div class="sectionM" v-for="(content,i) in contentLegales" :key="content.id">
                <h2>{{ content.titleH2 }}</h2>
                <ul v-if="(contentLegales[i].contentLi.length > 0)">
                    <li v-for="(csection,j) in contentLegales[i].contentLi" :key="j">
                        <p v-html="csection"></p>
                    </li>
                </ul>
                <p class="pSection" v-if="(contentLegales[i].pSection.length > 0)" v-html="contentLegales[i].pSection"></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PublicMLegales",
    data (){
        return {
            titleMlegales: "Mentions légales",
            subSection: "Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l'économie numérique, il est précisé aux utilisateurs du site www.akdevmatik.fr l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi.",
            contentLegales: [
                {
                    id: 1,
                    titleH2: "Données légales",
                    contentLi: [
                        "Responsable du site et directeur de la publication : Ahmed KRICHEL",
                        "Nom commerciale : akdevmatik",
                        "Forme juridique : Entreprise Individuelle (EI)",
                        "Adresse : 343 avenue de la costière, 30600 Vauvert - France",
                        "Email : <a href='mailto:akrichel@akdevmatik.fr'>akrichel@akdevmatik.fr</a>",
                        "SIRET : 91298060400017",
                        "TVA intra. : FR63912980604"
                    ],
                    pSection: ''
                },
                {
                    id: 2,
                    titleH2: "Hébergement du site",
                    contentLi: [
                        "Le site est hébergé chez LWS SAS",
                        "Immatriculation RCS Paris B 851 993 683 00024",
                        "Numéro de TVA : FR 21 851 993 683",
                        "Adresse : 10, rue penthievre, 75838 Paris - France",
                        "Téléphone : +33 177 62 30 03"
                    ],
                    pSection: ''
                },
                {
                    id: 3,
                    titleH2: "Modification du site",
                    contentLi: [],
                    pSection: "L'équipe éditoriale se réserve le droit de modifier ou de corriger le contenu de ce site et de ces mentions légales à tout moment et ceci sans préavis."
                },
                {
                    id: 4,
                    titleH2: "Propriété intellectuelle",
                    contentLi: [],
                    pSection: "Sauf mention contraire, tous les éléments accessibles sur le site (textes, images, graphismes, logo, icônes, sons, logiciels, etc.) restent la propriété exclusive de leurs auteurs, en ce qui concerne les droits de propriété intellectuelle ou les droits d’usage.<br><br/>Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de l’auteur.<br/><br/>Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il contient est considérée comme constitutive d’une contrefaçon et passible de poursuite d'après les dispositions des différents articles L.335-2 et suivants du Code de Propriété Intellectuelle."
                },
                {
                    id: 5,
                    titleH2: "Données personnelles & cookies",
                    contentLi: [],
                    pSection: "Le traitement de vos données à caractère personnel et l'utilisation des cookies sont régis par la politique de confidentialité du site, conformément au Règlement Général sur la Protection des Données 2016/679 du 27 avril 2016 (\"RGPD\").<br/><br/>Vous reconnaissez en avoir pris connaissance préalablement à toute utilisation du site."
                }
            ]
        }
    }
}
</script>

<style>
.container{
    margin: 10px;
}
.container h1{
    text-align: center;
    margin-bottom: 40px;
    color: white;
}
.container .subSection{
    line-height: 1.5;
    margin-bottom: 30px;
}
.container .content{
    padding: 10px;
    margin-bottom: 60px;
}
.sectionM h2{
    color: white;
    margin-bottom: 20px;
}
.sectionM ul{
    margin-bottom: 40px;
}
.sectionM li{
    list-style: square;
    margin: 0 30px;
    line-height: 1.5;
}
.sectionM li a{
    color: var(--color-mygreen);
    display: inline;
    text-decoration: none;
}
.sectionM .pSection{
    padding: 0 20px;
    line-height: 1.5;
    margin-bottom: 40px;
}
</style>